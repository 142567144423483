<template>
  <div class="admin-container">
    <template v-if="data != null">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Payments',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Platba</h3>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            label="Název platby *"
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="statuses"
            item-text="name"
            item-value="status"
            label="Stav platby"
            v-model="data.status"
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="payments"
            label="Pořadí platby"
            v-model="data.behind_payment"
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="data.description"
            label="Poznámka"
            auto-grow
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          ></v-textarea>
        </v-col>
      </v-row>

      <input
        style="display: none"
        ref="documentFile"
        type="file"
        class="input--file"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <input
        style="display: none"
        ref="invoiceFile"
        type="file"
        class="input--file"
        @input="handleInputFile($event.target.files[0], true)"
      />

      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-if="data.invoice_document_id"
          @click="downloadDoc(data.invoice_document_id)"
        >
          <!-- </div> -->
          <div class="document-added mr-md-6 cursor-pointer bg-primary-2">
            <!-- <div class="document-input-add-plus">
              <img :src="require('@/assets/stahnout_fakturu.svg')" alt="" />
            </div> -->
            <!-- <div
              class="document-text document-text--overflow document-text--name"
            >
              Stáhnout <br />
              fakturu
            </div> -->
            <div
              class="document-document-mini"
              :style="correctIconInvoice()"
            ></div>
            <div
              class="document-text"
              style="text-transform: uppercase; font-weight: bold"
            >
              Stáhnout<br />fakturu
            </div>
            <div class="document-close">
              <!-- <button
                @click="handleDeleteFile(invoice_document_id, null, true)"
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button> -->
            </div>
          </div>
          <!-- <v-text-field
            style="width: 150px"
            v-model="document.name"
            label="Název souboru *"
          ></v-text-field> -->
        </div>
        <template
          v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
        >
          <button
            v-if="!data.invoice_document_id"
            class="document-input-add"
            @click="handleClick('invoiceFile')"
          >
            <div class="document-input-add-plus">+</div>
            <div class="document-input-add-text">Vložit fakturu</div>
          </button>
        </template>
      </div>

      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-for="(document, documentIdx) in data.documents"
          :key="document.document_id"
          class="cursor-pointer"
        >
          <div
            class="document-added mr-md-6"
            @click="downloadDoc(document.document_id)"
          >
            <div
              class="document-document-mini"
              :style="correctIcon(document)"
            ></div>
            <div class="document-extension">
              {{
                document.document_original_extension
                  ? document.document_original_extension.toUpperCase()
                  : ''
              }}
            </div>
            <div
              class="document-text document-text--overflow document-text--name mr-2"
            >
              <!-- Dokument <br />{{ document.mimetype }} -->
            </div>
            <div
              class="document-close"
              v-if="user.role != 'END_USER' && user.role !== 'COMPANY_CUSTOMER'"
            >
              <button
                @click.stop="
                  (focusedId = document.document_id),
                    (focusedIdx = documentIdx),
                    (dialog = true)
                "
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <v-text-field
            style="width: 150px"
            v-model="document.name"
            label="Název souboru *"
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          ></v-text-field>
        </div>
        <button
          class="document-input-add"
          @click="handleClick('documentFile')"
          v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
        >
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit dokument</div>
        </button>
      </div>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            v-if="['SUPERUSER'].includes(user.role)"
            small
            tile
            color="error"
            class="mr-4"
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'Payments',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn
            small
            tile
            color="primary"
            v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
            @click="update"
            >Uložit</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col>
          <p class="col-secondary-3">
            Projektový manager: {{ data.project_manager.first_name }}
            {{ data.project_manager.last_name }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <v-btn small color="primary" tile @click="toMessages">
            <div class="d-flex flex-align-center">
              <img
                :src="require('@/assets/poslat_zpravu.svg')"
                class="icon-wrapp mr-2"
                style="width: 15px; height: 15px"
              />
              Poslat zprávu
            </div>
          </v-btn>
        </v-col>
      </v-row>

      <h3 class="mt-10">Historie</h3>
      <v-row>
        <v-col cols="12" md="8">
          <table class="table-changes">
            <tr v-for="change in data.changes" :key="change.id">
              <td class="col-secondary-2">{{ change.created_at }}</td>
              <td class="col-secondary-2">
                {{ change.editor.first_name }} {{ change.editor.last_name }}
              </td>
              <td class="col-secondary-2">{{ change.note }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit dokument</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit dokument ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    small
                    tile
                    width="100%"
                    @click="handleDeleteFile(focusedId, focusedIdx)"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="dialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="deleteDialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit platbu</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit platbu ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="error"
                    small
                    tile
                    width="100%"
                    @click="deletePayment"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="deleteDialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import paymentService from '../api/paymentService';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      data: null,
      dialog: false,
      focusedId: null,
      focusedIdx: null,
      deleteDialog: false,
      //   invoice_document_id: null,
      documents: [],
      statuses: [],
      payments: [],
    };
  },
  async created() {
    // permissionDenied(this.user, this.$router, "SUPERUSER", "PROJECT_MANAGER");
    bus.$emit('processing');
    await this.getPayments();
    await this.getPaymentStatuses();
    await this.show();
    bus.$emit('processing', false);
  },
  methods: {
    correctIconInvoice() {
      let icon = 'transparent_document.svg';
      if (IMAGES_EXT.includes(this.data.invoice_original_extension))
        icon = 'transparent_jpg.svg';
      if (PDF == this.data.invoice_original_extension) icon = 'transparent_pdf.svg';
      if (this.data.invoice_original_extension.includes(ZIP)) icon = 'transparent_zip.svg';
      if (this.data.invoice_original_extension.includes(PPT)) icon = 'transparent_ppt.svg';
      if (this.data.invoice_original_extension.includes(XLS)) icon = 'transparent_xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
        marginBottom: "10px"
      };
    },
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.document_original_extension))
        icon = 'jpg.svg';
      if (PDF == doc.document_original_extension) icon = 'pdf.svg';
      if (doc.document_original_extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.document_original_extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.document_original_extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async deletePayment() {
      try {
        bus.$emit('processing');
        await paymentService.destroy(this.$route.params.id);
        this.$router.push({ name: 'Payments' });
        bus.$emit('processing', false);
      } catch (error) {
        errorHandler(error);
        bus.$emit('processing', false);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    toMessages() {
      if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.data.project_manager.id },
          query: { payment: this.data.id },
        });
      }
      if (['PROJECT_MANAGER', 'SUPERUSER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().investor.id },
          query: { payment: this.data.id },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    async getPayments() {
      try {
        const data = await paymentService.index();
        // this.payments = data;
        for (let i = 0; i < data.length; i++) {
          this.payments.push(i + 1);
        }
        this.payments.push(this.payments.length + 1);
      } catch (error) {
        errorHandler(error);
      }
    },
    async show() {
      try {
        const data = await paymentService.show(this.$route.params.id);
        this.data = data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getPaymentStatuses() {
      try {
        const data = await paymentService.statuses();
        this.statuses = data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async handleDeleteFile(documentId, documentIdx, arg = null) {
      try {
        bus.$emit('processing');
        await documentService._delete(documentId);
        if (arg == null) {
          this.data.documents.splice(documentIdx, 1);
        } else {
          this.data.invoice_document_id = null;
          console.debug('data', this.data);
        }
        this.dialog = false;
        this.focusedId = null;
        this.focusedIdx = null;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f, arg = null) {
      try {
        console.debug(f);
        bus.$emit('processing');
        if (arg == null) {
          for (let fidx = 0; fidx < f.length; fidx++) {
            var { data } = await documentService.store(f[fidx]);
            this.data.documents.push({
              document_id: data.id,
              name: data.client_name,
              mimetype: data.mimetype,
              mime: data.mime,
              document_original_extension: data.extension,
            });
          }
          this.$refs.documentFile.values = [];
        } else {
          console.debug('set invoice');
          var { data } = await documentService.store(f);
          this.data.invoice_document_id = data.id;
          this.data.invoice_extension = data.extension
          console.debug('data', this.data);
        }
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async indexTimelines() {
      try {
        const data = await timelineService.index();
        this.timelines = data;
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async update() {
      try {
        bus.$emit('processing');
        const response = await paymentService.update(this.$route.params.id, {
          ...this.data,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Payments',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnoutFakturu() {
      return {
        backgroundImage: `url(${require('@/assets/stahnout_fakturu.svg')} !important`,
      };
    },
  },
  watch: {},
};
</script>

<style></style>
